import React, { PropsWithChildren } from "react";
import Block from "@onnit-js/ui/components/module/Block";
import { Img } from "@onnit-js/ui/components/image";
import { Text } from "@onnit-js/ui/components/text";
import Button, { ButtonProps } from "@onnit-js/ui/components/button/Button";
import useAddToBag from "@onnit-js/ui/hooks/useAddToBag";
import Media from "@onnit-js/ui/components/media";
import { useTheme } from "styled-components";
import { ThemeInterface } from "@onnit-js/ui/components/themes/interfaces";
import Video from "@onnit-js/ui/components/video/Video";
import { Asset, BlockModel, ContentfulButtonProps, ContentImage, ContentVideo } from "../../interfaces/SiteContent";
import SuperScriptSpecialChars from "../common/SuperScriptSpecialChars";

interface BlockProps {
    data: BlockModel;
    imgParams: string;
    position: number;
}

const ContentfulButton: React.FC<PropsWithChildren<ContentfulButtonProps>> = (props) => {
    const { addToBag, isAddingToBag } = useAddToBag();
    const { align, addToBagPid, addToBagSubscriptionInterval, children, ...rest } = props;
    const newProps: ButtonProps = { ...rest };

    switch (align) {
        case "right":
            newProps.ml = "auto";
            break;
        case "center":
            newProps.mx = "auto";
            break;
        default:
            break;
    }

    if (addToBagPid) {
        return (
            <Button
                {...newProps}
                disabled={isAddingToBag}
                onClick={(evt) => {
                    evt.preventDefault();
                    evt.nativeEvent.stopImmediatePropagation();
                    addToBag({
                        product_id: Number(addToBagPid),
                        quantity: 1,
                        subscription_interval: addToBagSubscriptionInterval ? Number(addToBagSubscriptionInterval) : null,
                    });
                }}
            >
                {isAddingToBag ? "Adding..." : `Add to Bag${addToBagSubscriptionInterval ? " & Subscribe" : ""}`}
            </Button>
        );
    }
    return <Button {...newProps}>{children}</Button>;
};

interface ContentfulBlockAssetProps {
    desktop: ContentImage | ContentVideo;
    mobile?: ContentImage | ContentVideo;
    imgParams: string;
    position: number;
}

interface AssetProps {
    asset: Asset
    imgParams: string;
    lazyLoad: boolean
}

const Asset: React.FC<AssetProps> = ({ asset, lazyLoad, imgParams }) => {
    if (asset.contentType === "video") {
        return (
            <Video
                key={1}
                autoPlay
                loop
                muted
                playsInline
                placeholder={[1440, 810]}
                title={asset.alt}
            >
                <source src={asset.src} type="video/mp4" />
            </Video>
);
    }

    return (
        <Img
            src={asset.src + (asset.src.includes(".gif") ? "" : imgParams)}
            alt={asset.alt}
            width={asset.width}
            height={asset.height}
            lazyLoad={lazyLoad}
        />
);
};

const ContentfulBlockAsset: React.FC<ContentfulBlockAssetProps> = ({ desktop, mobile, imgParams, position }) => {
    const theme = useTheme();

    if (mobile) {
        return (
            <Media query={(theme as ThemeInterface).mediaQueries.sm}>
                {(matches) => (matches
                        ? <Asset asset={desktop} lazyLoad={position > 2} imgParams={imgParams} />
                        : <Asset asset={mobile} lazyLoad={position > 2} imgParams={imgParams} />
                )}
            </Media>
        );
    }

    return <Asset asset={desktop} lazyLoad={position > 2} imgParams={imgParams} />;
};

const ContentfulBlock: React.FC<React.PropsWithChildren<React.PropsWithChildren<BlockProps>>> = ({
     data,
     imgParams,
     position,
 }) => {
    return (
        <Block
            defaultOnMobile={data.defaultOnMobile}
            href={data.url}
            contentLocation={data.contentLocation ?? "below-image"}
            target={data.openInNewWindow ? "_blank" : undefined}
            image={(
                <ContentfulBlockAsset
                    desktop={data.image}
                    mobile={data.mobileImage}
                    imgParams={imgParams}
                    position={position}
                />
)}
            title={
                data.title && (
                    <Text as="p" {...data.titleProps}>
                        <SuperScriptSpecialChars text={data.title} />
                    </Text>
                )
            }
            subTitle={
                data.subTitle && (
                    <Text
                        as="p"
                        {...data.subTitleProps}
                        ml={data.bodyProps?.maxWidth && data.bodyProps.textAlign === "right" ? "auto" : ""}
                    >
                        <SuperScriptSpecialChars text={data.subTitle} />
                    </Text>
                )
            }
            body={
                data.body && (
                    <Text
                        as="p"
                        {...data.bodyProps}
                        ml={data.bodyProps?.maxWidth && data.bodyProps.textAlign === "right" ? "auto" : ""}
                    >
                        <SuperScriptSpecialChars text={data.body} />
                    </Text>
                )
            }
            buttonLocation={data.buttonLocation ?? "below-image"}
            button={data.buttonText && <ContentfulButton {...data.buttonProps}>{data.buttonText}</ContentfulButton>}
        />
    );
};

export default ContentfulBlock;
