import React from "react";
import { Sup, Text } from "../../text";
import {
    TrialProductIds
} from "./trials";
import { Box, BoxProps, } from "../../box/Box";

interface TrialDisclaimerTextProps extends React.PropsWithChildren<BoxProps> {
    msrp: string | number;
    color?: string;
    showQualifyText?: boolean;
    showFooterText?: boolean;
}
interface DisclaimerProps extends TrialDisclaimerTextProps{
    trialProductId: TrialProductIds;
}

const calculatePrice = (priceMsrp: string | number) => {
    const price = typeof priceMsrp === "string" ? parseFloat(priceMsrp) : priceMsrp;
    return price - (price * 0.15);
};

export const QualifyText: React.FC = () => <>{" "}(Qualifying customers are new Onnit customers residing in the United States only).</>;

export const RebillText: React.FC<{rebillDays: number, color?: string}> = ({rebillDays, color}) => (
    <Text as="p" fontSize={0} lineHeight={6} color={color} mb={3}>
    Cancel the subscription any time within the first {rebillDays} days of ordering and we won’t send any additional
    product - it’s really that simple!</Text>
);

export const SharedFooter: React.FC<{ color?: string}> = ({ color = "grays.4"}) => {
    return (
        <>
            <Text as="p" fontSize={0} lineHeight={6} color={color} mb={3}>
                <Text
                    as="span"
                    color={color}
                    fontWeight="bold"
                    fontSize={0}
                    display="inline"
                >
                    {" "}Once opted in, you can cancel your subscription anytime in your{" "}
                    <Text
                        fontSize={0}
                        target="_blank"
                        display="inline"
                        color={color}
                        className="underline"
                        href="/cart/account.php"
                        as="a"
                    >account dashboard.
                    </Text>{" "}
                </Text>
                Should the subscription remain active, and recurring orders process but you do not wish to keep your
                subscription active, unopened product can be returned for a refund within 90 days and your subscription
                can be cancelled.
            </Text>
            <Text as="p" fontSize={0} lineHeight={6} color={color}>
                <a href="/terms-and-conditions/subscription/" target="_blank">
                    All details of our subscription program can be found <span style={{ fontWeight: "bold" }}>here</span>.
                </a>
            </Text>
        </>
    );
};

const AlphaBrainDisclaimerShort:React.FC<TrialDisclaimerTextProps> = ({ msrp, color = "grays.4", showQualifyText = true, children }) => {
    return (
        <>
            <Text as="p" fontSize={0} lineHeight={6} color={color} mb={3}>
                {children}This free trial purchase includes a subscription that autoships Alpha BRAIN
                <Sup variant="reg" /> 30ct 25 days after the initial order is placed, then every 15 days thereafter at
                15% off MSRP (${calculatePrice(msrp).toFixed(2)}) + shipping & tax.
                {showQualifyText && <QualifyText />}
            </Text>
            <RebillText rebillDays={25} color={color} />
        </>
    )
}

const AlphaBrainDisclaimer:React.FC<TrialDisclaimerTextProps> = (props) => {
    return (
        <>
            <AlphaBrainDisclaimerShort {...props} />
            <SharedFooter color={props.color} />
        </>
    );
};

const NewMoodDisclaimerShort:React.FC<TrialDisclaimerTextProps> = ({ msrp, color = "grays.4", showQualifyText = true, children }) => {
    return (
        <>
            <Text as="p" fontSize={0} lineHeight={6} color={color} mb={3}>
                {children}This free trial purchase includes a subscription that autoships New MOOD<Sup variant="reg" /> 30-Count 30
                days after the initial order is placed,
                and then every 15 days thereafter at 15% off MSRP (${calculatePrice(msrp).toFixed(2)})
                + shipping & tax. {showQualifyText && <QualifyText />}
            </Text>
            <RebillText rebillDays={30} color={color} />
        </>
    )
}

const NewMoodDisclaimer:React.FC<TrialDisclaimerTextProps> = (props) => {
    return (
        <Box>
            <Text as="p" fontSize={0} lineHeight={6} color={props.color} mb={3}>
                Customers signing up will receive a free 30-Count bottle (15-day supply), and we’ll send it for just the
                cost of shipping and tax.
            </Text>
            <NewMoodDisclaimerShort {...props} />
            <SharedFooter color={props.color} />
        </Box>
    );
};

const ShroomTechSportDisclaimerShort:React.FC<TrialDisclaimerTextProps> = ({ msrp, color = "grays.4", showQualifyText = true, children}) => {
    return (
        <>
            <Text as="p" fontSize={0} lineHeight={6} color={color} mb={3}>
                {children} This free trial purchase includes a subscription that autoships Shroom Tech® SPORT 28-Count 30 days
                after the initial order is placed, and then every 15 days
                thereafter at 15% off MSRP (${calculatePrice(msrp).toFixed(2)}) + shipping & tax.
                {showQualifyText && <QualifyText />}
            </Text>
            <RebillText rebillDays={25} color={color} />
        </>
    )
}

const ShroomTechSportDisclaimer:React.FC<TrialDisclaimerTextProps> = (props) => {
    return (
        <>
            <Text as="p" fontSize={0} lineHeight={6} color={props.color} mb={3}>
                Customers signing up will receive a free 28-count bottle (7-day supply), and we’ll send it for just the
                cost of shipping and tax.
            </Text>
            <ShroomTechSportDisclaimerShort {...props} />
            <SharedFooter color={props.color} />
        </>
    );
};

const TotalGutHealthDisclaimerShort:React.FC<TrialDisclaimerTextProps> = ({ msrp, color = "grays.4", showQualifyText = true, children }) => {
    return (
        <>
            <Text as="p" fontSize={0} lineHeight={6} color={color} mb={3}>
                {children}This free trial purchase includes a subscription that autoships Total GUT HEALTH
                <Sup variant="reg" /> 25 days after the initial order is placed, then every 15 days thereafter at 15%
                off MSRP ($
                {calculatePrice(msrp).toFixed(2)}) + shipping & tax.
                {showQualifyText && <QualifyText />}
            </Text>
            <RebillText rebillDays={25} color={color} />
        </>
    )
}

const TotalGutHealthDisclaimer:React.FC<TrialDisclaimerTextProps> = (props) => {
    return (
        <>
            <TotalGutHealthDisclaimerShort {...props} />
            <SharedFooter color={props.color} />
        </>
    );
};

const TotalHumanDisclaimerShort:React.FC<TrialDisclaimerTextProps> = ({
 msrp,
 color = "grays.4",
 showQualifyText = true,
 children }) => {
    return (
        <>
            <Text as="p" fontSize={0} lineHeight={6} color={color} mb={3}>
                {children}This free trial purchase includes a subscription that autoships Total Human
                <Sup variant="reg" /> 7-day supply in 17 days then every 7 days at 15% off MSRP ($
                {calculatePrice(msrp).toFixed(2)}) + shipping & tax.
                {showQualifyText && <QualifyText />}
            </Text>
            <RebillText rebillDays={17} color={color} />
        </>
    );
}

const TotalHumanDisclaimer = ({ msrp, color = "grays.4", showQualifyText = true }: TrialDisclaimerTextProps) => {
    return (
        <>
            <Text as="p" fontSize={0} lineHeight={6} color={color} mb={3}>
                Customers signing up will receive a free 7-Day Supply, and we’ll send it for just the cost of shipping
                and tax.
            </Text>
            <TotalHumanDisclaimerShort msrp={msrp} color={color} showQualifyText={showQualifyText} />   
            <SharedFooter color={color} />
        </>
    );
};

export const FullDisclaimer: React.FC<React.PropsWithChildren<DisclaimerProps>> = (
    {
        trialProductId,
        color = "grays.4",
        ...rest
    }) => {
    switch (trialProductId) {
        case TrialProductIds.AlphaBrain:
            return (<AlphaBrainDisclaimer color={color} {...rest} />);
        case TrialProductIds.TotalHuman:
            return (<TotalHumanDisclaimer color={color} {...rest} />);
        case TrialProductIds.NewMood:
            return (<NewMoodDisclaimer color={color} {...rest} />);
        case TrialProductIds.ShroomTechSport:
            return (<ShroomTechSportDisclaimer color={color} {...rest} />);
        case TrialProductIds.TotalGutHealth:
            return (<TotalGutHealthDisclaimer color={color} {...rest} />);
        default:
            return (<></>);
    }
};

export const ShortDisclaimer: React.FC<React.PropsWithChildren<DisclaimerProps>> = (
    {
        trialProductId,
        color = "grays.4",
        ...rest
    }) => {
    switch (trialProductId) {
        case TrialProductIds.AlphaBrain:
            return (<AlphaBrainDisclaimerShort color={color} {...rest} />);
        case TrialProductIds.TotalHuman:
            return (<TotalHumanDisclaimerShort color={color} {...rest} />);
        case TrialProductIds.NewMood:
            return (<NewMoodDisclaimerShort color={color} {...rest} />);
        case TrialProductIds.ShroomTechSport:
            return (<ShroomTechSportDisclaimerShort color={color} {...rest} />);
        case TrialProductIds.TotalGutHealth:
            return (<TotalGutHealthDisclaimerShort color={color} {...rest} />);
        default:
            return (<></>);
    }
};
