export enum TrialProduct {
    AlphaBrain = "ab",
    TotalHuman = "th",
    NewMood = "nm",
    ShroomTechSport = "sts",
    TotalGutHealth = "tgh",
}

export enum TrialProductIds {
    AlphaBrain = 8559,
    TotalHuman = 8503,
    NewMood = 3104,
    ShroomTechSport = 4326,
    TotalGutHealth = 9488,
}

